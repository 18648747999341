import {Api} from 'shared/services/axiosConfig';
import {format, addHours} from 'date-fns';

export interface ILatestSubscribers {
  dataAgenda: string;
  dataInscricao: string;
  emailLead: string;
  nomeCandidato: string;
  idLead: string;
  identificacaoLead: string;
  nomeEstabelecimento: string;
  nomeOferta: string;
  telefoneLead: string;
}

export interface INextInterviews {
  entrevistados: {
    identificacaoLead: string; 
    nomeOferta: string;
    nomeCandidato: string;
  }[];
  idDisponibilidadeDeAgenda: string;
  iniciaEm: string;
  nomeEntrevistador: string;
  nomeEstabelecimento: string;
}

const getLatestSubscribers = async (
  idEstabelecimento: string,
  userTimezone?: number,
  idPermissao?: string,
): Promise<ILatestSubscribers[] | undefined> => {
  try {
    const {data} = await Api.get(
      `/Home/UltimosInscritos?idEstabelecimento=${
        idEstabelecimento !== '0' ? idEstabelecimento : ''
      }&idPermissao=${idPermissao}`,
    );

    const response: ILatestSubscribers[] = data.map(
      (item: ILatestSubscribers) =>
        ({
          ...item,
          dataAgenda: item.dataAgenda
            ? format(
                addHours(
                  new Date(item.dataAgenda),
                  userTimezone ? userTimezone : -3,
                ),
                "dd/MM/yyyy' - 'HH:mm",
              )
            : ' - ',
          dataInscricao: item.dataInscricao
            ? format(
                addHours(
                  new Date(item.dataInscricao),
                  userTimezone ? userTimezone : -3,
                ),
                "dd/MM/yyyy' - 'HH:mm",
              )
            : ' - ',
          telefoneLead: item.telefoneLead ? item.telefoneLead : ' - ',
          emailLead: item.emailLead ? item.emailLead : ' - ',
        } as ILatestSubscribers),
    );

    return response;
  } catch (error) {
    throw new Error('Erro ao listar últimos inscritos!');
  }
};

const getNextInterviews = async (
  idEstabelecimento: string,
  somenteEntrevistasDoUsuarioLogado: boolean,
  userTimezone?: number,
  idPermissao?: string,
): Promise<INextInterviews[] | undefined> => {
  try {
    const {data} = await Api.get(
      `/Home/ProximasEntrevistas?idEstabelecimento=${
        idEstabelecimento !== '0' ? idEstabelecimento : ''
      }&somenteEntrevistasDoUsuarioLogado=${somenteEntrevistasDoUsuarioLogado}&idPermissao=${idPermissao}`,
    );

    const response: INextInterviews[] = data.map(
      (item: INextInterviews) =>
        ({
          ...item,
          iniciaEm: item.iniciaEm
            ? format(
                addHours(
                  new Date(item.iniciaEm),
                  userTimezone ? userTimezone : -3,
                ),
                "dd/MM/yyyy' - 'HH:mm",
              )
            : ' - ',
        } as INextInterviews),
    );

    return response;
  } catch (error) {
    throw new Error('Erro ao listar próximas entrevistas!');
  }
};

export const HomeService = {
  getLatestSubscribers,
  getNextInterviews,
};
