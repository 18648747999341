/**
 * Contém variáveis de ambiente para mais fácil utilização
 */
export const Environment = {
  /**
   * Informa a url para as APIs do Central de conversão
   */

  ID_TENANT: 'area87gvcollege2',

  ID_CLIENT: 'CentralDeConversao',

  URL_API:
    window.location.host.includes('localhost') ||
    window.location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br/conversao/api/v1`
      : window.location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br/conversao/api/v1`
      : `https://api.gvdasa.com.br/conversao/api/v1`,

  API_GATEWAY:
    window.location.host.includes('localhost') ||
    window.location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br/`
      : window.location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br/`
      : `https://api.gvdasa.com.br/`,

  URL_FORM: location.host.includes('localhost')
    ? 'https://area87gvcollege2-dev.crmform.gvdasa.com.br'
    : `https://${location.host.split('.')[0]}.crmform.gvdasa.com.br`,

  URL_ORIGIN_DEBUG: window.location.host.includes('localhost')
    ? 'https://area87gvcollege2-dev.conversao.gvdasa.com.br/'
    : '',

  URL_WC_FEEDABACK:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://wc-feedbacks.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://wc-feedbacks-hml.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'https://wc-feedbacks-dev.gvdasa.com.br'
      : 'https://wc-feedbacks-dev.gvdasa.com.br',
};
