import {Api} from 'shared/services/axiosConfig';

export interface IActiveCampaing {
  id: string;
  campanha: string;
  estabelecimento: string;
  dataInicial: Date;
  dataFinal: Date;
}

export interface ILeadsBySituation {
  lead: string;
  situacao: string;
  visitante: boolean;
  inscrito: boolean;
  convertido: boolean;
  atendidoPor: string;
  tempoMedioDeAtendimento: number;
  ultimoAtendimentoEm: number;
  campanha: string;
}

export interface IConversionByCampaing {
  lead: string;
  situacao: string;
  visitante: boolean;
  inscrito: boolean;
  convertido: boolean;
  atendidoPor: string;
  tempoMedioDeAtendimento: number;
  ultimoAtendimentoEm: number;
  campanha: string;
}

export interface IGeneralPerformance {
  lead: string;
  situacao: string;
  formularioRecebido: string;
  emAtendimento: string;
  convertido: string;
  atendidoPor: string;
  tempoMedioDeAtendimento: number;
  ultimoAtendimentoEm: Date;
  campanha: string;
}

export interface IServiceTimeAverage {
  idLead: string;
  lead: string;
  tempo: number;
  situacao: string;
  emRelacaoAMedia: string;
  ultimoAtendimento: Date;
  incluidoEmDoLead: Date;
}

export interface IServiceWithout {
  idLead: string;
  lead: string;
  tempo: number;
  ultimoAtendente: string;
  ultimoAtendimento: string;
  dataUltimoAtendimento: Date;
}

export interface IPercentualVisitantesPeriodoPassado {
  valor: number;
  positivo: boolean;
  countPeriodoAtual: number;
  countPeriodoAnterior: number;
}

export interface IPercentualInscritoAgendadoPeriodoPassado {
  valor: number;
  positivo: boolean;
  countPeriodoAtual: number;
  countPeriodoAnterior: number;
}

export interface IPercentualConversoesPeriodoPassado {
  valor: number;
  positivo: boolean;
  countPeriodoAtual: number;
  countPeriodoAnterior: number;
}

export interface IFunil {
  numeroDeVisitantes: number;
  numeroDeAtendimentos: number;
  numeroDeConversoes: number;
  percentualDeInscritoAgendado: number;
  percentualConvertido: number;
  percentualVisitantesPeriodoPassado: IPercentualVisitantesPeriodoPassado;
  percentualInscritoAgendadoPeriodoPassado: IPercentualInscritoAgendadoPeriodoPassado;
  percentualConversoesPeriodoPassado: IPercentualConversoesPeriodoPassado;
}

interface ISituacao {
  situacaoLead: string;
  valor: number;
}
export interface ILeadsPorSituacao {
  idFunil: string;
  nome: string;
  situacoes: ISituacao[];
}

export interface IConversaoPorAtendente {
  atendente: string;
  valor: number;
}

export interface IConversaoPorCampanha {
  campanha: string;
  valor: number;
}

export interface IAtendimentoXConversaoPorPeriodo {
  data: Date;
  atendidos: number;
  convertidos: number;
}

export interface IOrigemLead {
  origemLeadDescricao: 'Interno' | 'Externo';
  funil: IFunil;
}

export type TColor = 'amarelo' | 'vermelho' | '';

export interface IDashboard {
  taxaDeConversao: number;
  taxaDeConversaoCor: TColor;
  numeroDeCampanhasAtivas: number;
  numeroDeCampanhasAtivasCor: TColor;
  tempoMedioEntreAtendimentos: number;
  tempoMedioEntreAtendimentosCor: TColor;
  numeroLeadsSemInteracao: number;
  numeroLeadsSemInteracaoCor: TColor;
  origemLeads: IOrigemLead[];
  leadsPorSituacao: ILeadsPorSituacao[];
  conversaoPorAtendente: IConversaoPorAtendente[];
  conversaoPorCampanha: IConversaoPorCampanha[];
  atendimentoXConversaoPorPeriodo: IAtendimentoXConversaoPorPeriodo[];
}

export interface ICallsConversions {
  id: number;
  idLead: string;
  data: Date;
  lead: string;
  situacao: string;
}

export interface ICampanha {
  id: string;
  nome: string;
}

export interface IEstabelecimento {
  id: string;
  nome: string;
  campanhas: ICampanha[];
}

export interface IPeriodo {
  idPeriodo: number;
  descricao: string;
}

export interface IFiltro {
  estabelecimentos: IEstabelecimento[];
  periodos: IPeriodo[];
}

export interface ILeadsSemAtendimento {
  idLead: string;
  lead: string;
  tempo: number;
  ultimoAtendente: string;
  ultimoAtendimento: string;
  dataUltimoAtendimento: Date;
}

export interface IConversionRate {
  chave: string;
  valor: number;
  cores: ICores[];
  periodicidade: IPeriodicidade[];
}
export interface ILeadsWithoutConfig {
  chave: string;
  valor: number;
  cores: ICores[];
  periodicidade: IPeriodicidade[];
}

export interface IServiceTimeAverageConfig {
  chave: string;
  valor: number;
  cores: ICores[];
  periodicidade: IPeriodicidade[];
}
export interface ICores {
  nome: string;
  inicio: number;
  fim: number;
}

export interface IPeriodicidade {
  valor: string;
  selecionado: boolean;
}

export interface IPostConversionRate {
  chave: string;
  periodicidade: string;
  valor: number;
  cores: ICores[];
}

const getConversionRate = async (): Promise<IConversionRate> => {
  try {
    const {data} = await Api.get(
      `/Dashboard/Configuracoes?chave=taxadeconversao`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getLeadsWithoutConfig = async (): Promise<ILeadsWithoutConfig> => {
  try {
    const {data} = await Api.get(
      `/Dashboard/Configuracoes?chave=leadssematendimento`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getServiceTimeAverageConfig =
  async (): Promise<IServiceTimeAverageConfig> => {
    try {
      const {data} = await Api.get(
        `/Dashboard/Configuracoes?chave=tempomedioatendimento`,
      );

      return data;
    } catch (error: any) {
      throw new Error(`Erro ao listar. ${error.mensagens}`);
    }
  };

const getFiltro = async (): Promise<IFiltro> => {
  try {
    const {data} = await Api.get(`/dashboard/filtros`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getLeadsSemAtendimento = async (
  selectedPeriod: string,
  selectedEstablishment: string,
  selectedCampaing: string,
): Promise<ILeadsSemAtendimento[]> => {
  try {
    const {data} = await Api.get(
      `/dashboard/LeadsSemAtendimento?periodo=${selectedPeriod}&estabelecimento=${selectedEstablishment}&campanha=${selectedCampaing}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getActiveCampaing = async (
  establishment: string,
): Promise<IActiveCampaing[]> => {
  try {
    const getURL =
      establishment !== '0'
        ? `/dashboard/campanhasativas?estabelecimento=${establishment}`
        : `/dashboard/campanhasativas`;

    const {data} = await Api.get(getURL);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getLeadsBySituation = async (
  selectedPeriod: string,
  selectedEstablishment: string,
  selectedCampaing: string,
  idFunil: string,
): Promise<ILeadsBySituation[]> => {
  try {
    const {data} = await Api.get(
      `/dashboard/leads?estabelecimento=${selectedEstablishment}&campanha=${selectedCampaing}&periodo=${selectedPeriod}&idFunil=${idFunil}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getConversionByCampaing = async (
  selectedPeriod: string,
  selectedEstablishment: string,
  selectedCampaing: string,
): Promise<IConversionByCampaing[]> => {
  try {
    const {data} = await Api.get(
      `/dashboard/leads?estabelecimento=${selectedEstablishment}&campanha=${selectedCampaing}&periodo=${selectedPeriod}`,
    );

    const filteredData = data.filter(
      (lead: IGeneralPerformance) => lead.convertido.toLowerCase() === 'true',
    );

    return filteredData;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getGeneralPerformance = async (
  selectedPeriod: string,
  selectedEstablishment: string,
  selectedCampaing: string,
): Promise<IGeneralPerformance[]> => {
  try {
    const {data} = await Api.get(
      `/dashboard/leads?estabelecimento=${selectedEstablishment}&campanha=${selectedCampaing}&periodo=${selectedPeriod}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getServiceTimeAverage = async (
  selectedPeriod: string,
  selectedEstablishment: string,
  selectedCampaing: string,
): Promise<IServiceTimeAverage[]> => {
  try {
    const {data} = await Api.get(
      `/dashboard/tempomedioatendimento?periodo=${selectedPeriod}&estabelecimento=${selectedEstablishment}&campanha=${selectedCampaing}`,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getServiceLeadsWithout = async (): Promise<ILeadsSemAtendimento[]> => {
  try {
    const {data} = await Api.get(`/dashboard/LeadsSemAtendimento`);

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getDashboard = async (
  estabelecimento?: string,
  campanha?: string,
  periodo?: string,
  dataInicial?: Date,
  dataFinal?: Date,
  idTenant?: string,
): Promise<IDashboard> => {
  try {
    const {data} = await Api.get(
      `/dashboard?Estabelecimento=${estabelecimento}&Campanha=${campanha}&Periodo=${periodo}&DataInicial=${
        dataInicial || ''
      }&DataFinal=${dataFinal || ''}&Idtenant=${idTenant || ''}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const getCallsConversions = async (
  selectedPeriod: string,
  selectedEstablishment: string,
  selectedCampaing: string,
): Promise<ICallsConversions[]> => {
  try {
    const {data} = await Api.get(
      `/dashboard/AtendimentosEConvertidos?periodo=${selectedPeriod}&estabelecimento=${selectedEstablishment}&campanha=${selectedCampaing}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const postTaxaConversao = async (
  payload: IPostConversionRate,
  chave: string,
): Promise<IPostConversionRate> => {
  try {
    const response = await Api.post(
      `/Dashboard/Configuracoes?chave=${chave}`,
      payload,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

export const CRMDashboardService = {
  postTaxaConversao,
  getActiveCampaing,
  getLeadsBySituation,
  getConversionByCampaing,
  getGeneralPerformance,
  getServiceTimeAverage,
  getServiceLeadsWithout,
  getDashboard,
  getCallsConversions,
  getFiltro,
  getLeadsSemAtendimento,
  getConversionRate,
  getLeadsWithoutConfig,
  getServiceTimeAverageConfig,
};
