import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  MenuItem,
  TableRow,
  useTheme,
  TextField,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  TableContainer,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {ExpandMore} from '@material-ui/icons';
import {useNavigate, useParams} from 'react-router-dom';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

import {
  ILeadData,
  FormService,
  IFieldOfCampaign,
  IValidationGroup,
  ISituacaoFicha,
} from 'shared/services/api/FormService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import {Button, Footer, TableSkeleton} from 'shared/components';
import {CampaignService} from 'shared/services/api/CampaignService';
import {useCampaign, useTerm, useTitle, useUser} from 'shared/hooks';
import {StepperCampaign} from 'pages/Campaign/components/StepperCampaign';

import {useStyles} from './styles';
import {CollapseInfo} from './CollapseInfo';
import {ModalSection} from './ModalSection';

export const CampaignApplicationForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [nameCampaign, setNameCampaign] = useState('');
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [openIncludeModal, setOpenIncludeModal] = useState(false);
  const [fieldOfCampaign, setFieldOfCampaign] = useState<IFieldOfCampaign[]>(
    [],
  );
  const [validationGroups, setValidationGroups] = useState<IValidationGroup[]>(
    [],
  );
  const [possuiTaxa, setPossuiTaxa] = useState(false);
  const [leadData, setLeadData] = useState<ILeadData>({
    idCampoEmailLead: '',
    idCampoIdentifLead: '',
    idCampoTelefoneLead: '',
    idCampoCandidatoLead: '',
    idCampoDocumentoDevedorTaxaLead: '',
    idCampoNomeDevedorTaxaLead: '',
  } as ILeadData);

  const theme = useTheme();
  const {terms} = useTerm();
  const navigate = useNavigate();
  const {setTitle} = useTitle();
  const {data: user} = useUser();
  const {tableCell} = useStyles();
  const {id = '0'} = useParams<'id'>();
  const {getSections, onDragEnd, sections, orderSections, isSchedule} =
    useCampaign();

  const permissions = useMemo(
    () => user?.permissoes.filter((i) => i.nome.includes('Campanha')),
    [user?.permissoes],
  );

  const canList = useMemo(
    () => !!permissions?.find((i) => i.nome.includes('Listar')),
    [permissions],
  );

  const handleGetFieldsOfCampaign = useCallback(async () => {
    try {
      const response = await FormService.getFieldsOfCampaign(id);

      if (response) setFieldOfCampaign(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id, openIncludeModal]);

  const handleNextEvent = useCallback(async () => {
    if (!isEditing) {
      navigate(`/campanha/personalizacao/${id}`);
    }

    if (!leadData.idCampoEmailLead || !leadData.idCampoIdentifLead) {
      setSubmitted(true);

      return;
    }

    if (
      possuiTaxa &&
      (!leadData.idCampoDocumentoDevedorTaxaLead ||
        !leadData.idCampoNomeDevedorTaxaLead)
    ) {
      setSubmitted(true);

      return;
    }
    setLoading(true);
    try {
      await FormService.patchLeadData(id, leadData);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    if (orderSections.length > 0) {
      const payload = {
        items: orderSections,
      };

      try {
        await FormService.updateOrderSection(payload);
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
    }
    navigate(`/campanha/personalizacao/${id}`);
    setLoading(false);
  }, [isEditing, leadData, possuiTaxa, orderSections, navigate, id]);

  const [idFunil, setIdFunil] = useState('');

  const handleGetCampaign = useCallback(async () => {
    try {
      const response = await CampaignService.getCampaign({
        id: id,
        verificarPermissaoEditar: true,
      });

      if (response) {
        setIsEditing(response.permiteEditar);
        setIsIntegrated(response.idTipoDeIntegracao !== 1);
        setNameCampaign(response.nome);
        setPossuiTaxa(response.possuiTaxa);
        setIdFunil(response.idFunil);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [id]);

  const handleGets = useCallback(async () => {
    setLoading(true);
    try {
      await handleGetCampaign();
      handleGetFieldsOfCampaign();
      await getSections(id);
      const data = await FormService.getLeadData(id);
      if (data) {
        setLeadData({...data, idCampanha: id});
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [getSections, handleGetCampaign, handleGetFieldsOfCampaign, id]);

  const handleGetValidationCondition = useCallback(async () => {
    try {
      const response = await FormService.getValidationCondition();
      setValidationGroups(response);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, []);

  useEffect(() => {
    if (!canList) {
      navigate('/');
    }
  }, [canList, navigate]);

  useEffect(() => {
    setTitle(`Campanha ${nameCampaign} | Ficha de Inscrição`);
  }, [setTitle, nameCampaign]);

  const [situacoesFicha, setSituacoesFicha] = useState<ISituacaoFicha[]>([]);

  const getSecaoDaFicha = useCallback(async () => {
    if (situacoesFicha || idFunil) {
      try {
        const response = await FormService.getSituacoesFicha(idFunil);
        setSituacoesFicha(response);
      } catch (error) {
        feedback(errorResponse(error), 'error');
      }
    }
  }, [idFunil, situacoesFicha]);

  useEffect(() => {
    idFunil && getSecaoDaFicha();
  }, [idFunil]);

  useEffect(() => {
    handleGets();
    handleGetValidationCondition();
  }, [handleGetValidationCondition, handleGets]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StepperCampaign
            integrated={isIntegrated}
            loading={loading}
            activeStep={4}
            idCampaign={id}
          />
          <Box marginBottom={1} />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <Box
                    width="100%"
                    padding={theme.spacing(3, 2)}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`1px solid ${theme.palette.divider}`}>
                    <Typography variant="h5">Seções do formulário</Typography>
                    <Button
                      label="Adicionar seção"
                      disabled={loading || !isEditing}
                      onClick={() => setOpenIncludeModal(true)}
                    />
                  </Box>
                </TableRow>
              </TableBody>
            </Table>

            {loading ? (
              <TableSkeleton btnAct={2} columns={5} rowsBody={5} />
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="2">
                  {(provided) => (
                    <TableContainer
                      {...provided.droppableProps}
                      draggable={!isEditing}
                      ref={provided.innerRef}>
                      <TableRow style={{width: '100%'}}>
                        <TableCell className={tableCell} width="14%">
                          Ações
                        </TableCell>
                        <TableCell className={tableCell} width="30%">
                          Título
                        </TableCell>
                        <TableCell className={tableCell} width="20%">
                          Envio do Formulário
                        </TableCell>
                        <TableCell className={tableCell} width="20%">
                          Seção predefinida
                        </TableCell>
                        <TableCell className={tableCell} width="20%">
                          Visível no formulário
                        </TableCell>
                        <TableCell className={tableCell} width="14%">
                          Nº de colunas
                        </TableCell>
                        <TableCell
                          className={tableCell}
                          width="6%"
                          align="right">
                          <IconButton disabled>
                            <ExpandMore style={{color: 'transparent'}} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {provided.placeholder}
                      {sections.map((item, index) => (
                        <Draggable
                          key={item.ordem}
                          draggableId={String(item.ordem)}
                          index={index}>
                          {(provided) => (
                            <TableContainer
                              component={Paper}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              <CollapseInfo
                                formSteps={situacoesFicha}
                                dataSection={item}
                                isEditing={isEditing}
                                useSchedule={isSchedule}
                                handleGetFields={handleGetFieldsOfCampaign}
                                validationGroups={validationGroups}
                                onCloseModal={handleGetFieldsOfCampaign}
                              />
                            </TableContainer>
                          )}
                        </Draggable>
                      ))}
                    </TableContainer>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Box marginBottom={2} marginTop={4}>
            <Typography variant="h6">
              <strong>Dados de identificação {terms.termoLead}</strong>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container xs={12} spacing={3}>
        <Grid item xs={12} sm={4}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Campo de identificação"
              variant="outlined"
              disabled={!isEditing}
              error={!leadData.idCampoIdentifLead && submitted}
              helperText={
                !leadData.idCampoIdentifLead && submitted && 'Campo requerido'
              }
              value={leadData.idCampoIdentifLead}
              onChange={({target}) =>
                setLeadData(
                  (state) =>
                    (state = {
                      ...state,
                      idCampoIdentifLead: target.value,
                    }),
                )
              }
              defaultValue={0}
              select>
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {fieldOfCampaign?.map((item) => (
                <MenuItem key={item.idCampo} value={item.idCampo}>
                  {item.nomeSecaoCampo}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Campo de Candidato"
              disabled={!isEditing}
              variant="outlined"
              value={leadData.idCampoCandidatoLead}
              onChange={({target}) =>
                setLeadData(
                  (state) =>
                    (state = {
                      ...state,
                      idCampoCandidatoLead: target.value,
                    }),
                )
              }
              defaultValue={0}
              select>
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {fieldOfCampaign?.map((item) => (
                <MenuItem key={item.idCampo} value={item.idCampo}>
                  {item.nomeSecaoCampo}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Campo de E-mail"
              disabled={!isEditing}
              variant="outlined"
              error={!leadData.idCampoEmailLead && submitted}
              helperText={
                !leadData.idCampoEmailLead && submitted && 'Campo requerido'
              }
              value={leadData.idCampoEmailLead}
              onChange={({target}) =>
                setLeadData(
                  (state) =>
                    (state = {
                      ...state,
                      idCampoEmailLead: target.value,
                    }),
                )
              }
              defaultValue={0}
              select>
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {fieldOfCampaign?.map((item) => (
                <MenuItem key={item.idCampo} value={item.idCampo}>
                  {item.nomeSecaoCampo}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        <Grid item xs={12} sm={4}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Campo de Telefone"
              disabled={!isEditing}
              variant="outlined"
              value={leadData.idCampoTelefoneLead}
              onChange={({target}) =>
                setLeadData(
                  (state) =>
                    (state = {
                      ...state,
                      idCampoTelefoneLead: target.value,
                    }),
                )
              }
              defaultValue={0}
              select>
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {fieldOfCampaign?.map((item) => (
                <MenuItem key={item.idCampo} value={item.idCampo}>
                  {item.nomeSecaoCampo}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box marginBottom={2} marginTop={4}>
          <Typography variant="h6">
            <strong>Dados de pagamento</strong>
          </Typography>
        </Box>
      </Grid>

      <Grid container xs={12} spacing={3}>
        <Grid item xs={12} sm={4}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Nome devedor"
              variant="outlined"
              disabled={!isEditing}
              value={leadData.idCampoNomeDevedorTaxaLead}
              error={
                !leadData.idCampoNomeDevedorTaxaLead && possuiTaxa && submitted
              }
              helperText={
                !leadData.idCampoNomeDevedorTaxaLead &&
                possuiTaxa &&
                submitted &&
                'Campo requerido'
              }
              onChange={({target}) =>
                setLeadData(
                  (state) =>
                    (state = {
                      ...state,
                      idCampoNomeDevedorTaxaLead: target.value,
                    }),
                )
              }
              defaultValue={0}
              select>
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {fieldOfCampaign?.map((item) => (
                <MenuItem key={item.idCampo} value={item.idCampo}>
                  {item.nomeSecaoCampo}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>

        <Grid item xs={12} sm={4}>
          {loading ? (
            <Skeleton variant="rect" height={36} />
          ) : (
            <TextField
              fullWidth
              label="Documento devedor"
              variant="outlined"
              disabled={!isEditing}
              value={leadData.idCampoDocumentoDevedorTaxaLead}
              error={
                !leadData.idCampoDocumentoDevedorTaxaLead &&
                possuiTaxa &&
                submitted
              }
              helperText={
                !leadData.idCampoDocumentoDevedorTaxaLead &&
                possuiTaxa &&
                submitted &&
                'Campo requerido'
              }
              onChange={({target}) =>
                setLeadData(
                  (state) =>
                    (state = {
                      ...state,
                      idCampoDocumentoDevedorTaxaLead: target.value,
                    }),
                )
              }
              defaultValue={0}
              select>
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {fieldOfCampaign?.map((item) => (
                <MenuItem key={item.idCampo} value={item.idCampo}>
                  {item.nomeSecaoCampo}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>
      <Box marginY={14} />

      <Footer
        onCancel={() => navigate('/campanha')}
        onSave={handleNextEvent}
        onGoBack={() => navigate(-1)}
        onSaveLabel={isEditing ? 'Salvar e Avançar' : 'Avançar'}
        disabled={loading}
      />

      <ModalSection
        formSteps={situacoesFicha}
        open={openIncludeModal}
        onClose={() => setOpenIncludeModal(false)}
      />
    </>
  );
};
